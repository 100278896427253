import {API} from "aws-amplify";
import {Auth} from "aws-amplify";

const fetchApiData = async () => {
    const myInit = {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`
        }
    };

    try {
      const apiResponse = await API.get('reportsapi', 'fundsrequests', myInit);
      return apiResponse;
    } catch (error) {
      console.error('API Error:', error);
      return null;
    }
  };
  
export { fetchApiData };