import React from "react";
import "./App.css";
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import GridComponent from "./GridComponent";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: "reportsapi",
        endpoint: "https://pciiqrig3h.execute-api.us-east-2.amazonaws.com/dev/"
      }
    ]
  }
});


export default function App() {
  return (
    <Authenticator hideSignUp={true} className={"centered-div"}>
      {({ signOut, user }) => (
          <main>
            <nav className="navbar">
              <div className="navbar-brand">
                <h1>Hello {user.username}</h1>
              </div>
              <div className="navbar-menu">
                <button className="sign-out-button" onClick={signOut}>Sign out</button>
              </div>
            </nav>
            <div className="App">
              <GridComponent />
            </div>
        </main>
      )}
    </Authenticator>
  );
}