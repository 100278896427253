import React, { useEffect, useState } from "react";
import { fetchApiData } from "./Pge.Reports.Api";
import "./GridComponent.css";

const GridComponent = () => {
    const [gridData, setGridData] = useState([]);

    useEffect(() => {
        async function fetchData() {
          const apiResponse = await fetchApiData();
          if (apiResponse) {
            setGridData(apiResponse); // Assuming the API response is an array of data
          }
        }
        fetchData();
      }, []);

    const [expandedRows, setExpandedRows] = useState({});

    const toggleRowExpansion = (requestId) => {
        setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [requestId]: !prevExpandedRows[requestId],
        }));
    };

    const getRatingEnumLabel = (ratingEnum) => {
        if (ratingEnum === 0) {
            return "Pass";
        } else if (ratingEnum === 2) {
            return "Fail";
        } else {
            return "Unknown";
        }
    };

    const getPassFailCounts = (ratings) =>{
        var passCount = 0;
        var failCount = 0;
        ratings.forEach((rating) => {
            if (rating.ratingEnum === 0) {
                passCount++;
            } else if (rating.ratingEnum === 2) {
                failCount++;
            }
        });
        return "Pass: " + passCount + " Fail: " + failCount;

    }

    const handleMouseEnter = (e, description) => {
        const tooltip = document.createElement("div");
        tooltip.className = "tooltip";
        tooltip.textContent = description;
        document.body.appendChild(tooltip);

        const rect = e.target.getBoundingClientRect();
        tooltip.style.top = `${rect.top + window.scrollY - tooltip.offsetHeight}px`;
        tooltip.style.left = `${rect.left + window.scrollX}px`;
    };

    const handleMouseLeave = () => {
        const tooltip = document.querySelector(".tooltip");
        if (tooltip) {
            document.body.removeChild(tooltip);
        }
    };

    const formatCurrency = (amount) => {
        const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD", // Change this to your desired currency code
            minimumFractionDigits: 2,
        });

        return formatter.format(amount);
    };

    const getRatingNumericValueById = (ratings, id) => {
        var ratingNumericValue = 0;
        ratings.forEach((rating) => {
            if (rating.ratingId === id) {
                ratingNumericValue = rating.numericResult;
            }
        });
        return ratingNumericValue;
    }

    const renderRatings = (ratings) => {
        return (
            <div className="sub-row">
                <h3>Ratings</h3>
                <h4>{getPassFailCounts(ratings)}</h4>
                <table className="ratings-table">
                    <thead>
                    <tr>
                        <th></th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Pass/Fail</th>
                        <th>Reason</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ratings.map((rating) => (
                        <tr key={rating.ratingId}>
                            <td>
                                <div
                                className="rating-description"
                                onMouseEnter={(e) => handleMouseEnter(e, rating.ratingDescription)}
                                onMouseLeave={handleMouseLeave}><span className="tooltip-indicator">ℹ️</span>
                                </div>
                            </td>
                            <td>{rating.ratingId}</td>
                            <td>{rating.ratingName}</td>
                            <td>{getRatingEnumLabel(rating.ratingEnum)}</td>
                            <td>{rating.ratingReason}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderFundsRequestInfo = (gridData)  => {
        var totalRequestAmount = 0;
        var totalRequestCount = 0;
        var totalEventsCount = 0;
        var eventIds = [];

        gridData.forEach((item) => {
           totalRequestCount++;
             totalRequestAmount += item.requestAmount;
             if(!eventIds.includes(item.eventId)){
                 totalEventsCount++;
                 eventIds.push(item.eventId);
             }
        });
        return (
            <div className="grid-container">
                <table className="grid-table">
                    <thead>
                        <tr>
                            <th>Total Requests</th>
                            <th>Total Events</th>
                            <th>Total Requests Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{totalRequestCount}</td>
                            <td>{totalEventsCount}</td>
                            <td>{formatCurrency(totalRequestAmount)}</td>
                        </tr>
                        </tbody>
                </table>
            </div>
        );

    }

    return (
        <div className="grid-container">
            <h1>Funds Request Grid</h1>

            {renderFundsRequestInfo(gridData)}

            <table className="grid-table">
                <thead>
                <tr>
                    <th></th>
                    <th>Request ID</th>
                    <th>Request Amount</th>
                    <th>Event ID</th>
                    <th>Event Name</th>
                    <th>Event Date</th>
                    <th>Organizer Verified</th>
                    <th>101</th>
                </tr>
                </thead>
                <tbody>
                {gridData.map((item) => (
                    <React.Fragment key={item.requestId}>
                        <tr>
                            <td className="expand-cell">
                                <button
                                    className="expand-button"
                                    onClick={() => toggleRowExpansion(item.requestId)}
                                >
                                    {expandedRows[item.requestId] ? "-" : "+"}
                                </button>
                            </td>
                            <td>{item.requestId}</td>
                            <td>{formatCurrency(item.requestAmount)}</td>
                            <td>{item.eventId}</td>
                            <td>{item.eventName}</td>
                            <td>{new Date(item.eventDate).toLocaleDateString()}</td>
                            <td>{item.organizerVerified}</td>
                            <td>{getRatingNumericValueById(item.ratings, 101)}</td>
                        </tr>
                        {expandedRows[item.requestId] && (
                            <tr>
                                <td colSpan="2"></td>
                                <td colSpan="5">{renderRatings(item.ratings)}</td>
                            </tr>
                        )}
                    </React.Fragment>
                ))}
                </tbody>
            </table>
        </div>
        
    );
};


export default GridComponent;